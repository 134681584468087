(function($) {

mapMultiple = function() {

  var directionsLabel = "Directions";

  if($('body.lang-fr').length){
    directionsLabel = "Itinéraire";
  }

  // Set locations (geoloc) array
  var locations = [];

  // Update locations array
  locations.length = 0;
  $('#multiple-map-data .item').each(function(){
    var latitude  = $(this).attr('data-mmap-latitude');
    var longitude = $(this).attr('data-mmap-longitude');
    var city      = $(this).attr('data-mmap-city');
    var address   = $(this).attr('data-mmap-address');
    var street    = $(this).attr('data-mmap-street');
    var province  = $(this).attr('data-mmap-province');
    var postalcode= $(this).attr('data-mmap-postalcode');
    var phone     = $(this).attr('data-mmap-phone');
    var mapLink   = $(this).attr('data-mmap-link');


    if (latitude && longitude) {
      locations.push([latitude,longitude, city, address, street, province, postalcode, phone, mapLink]);
    }
  });

  var marker, i;
  var LatLngList = [];
  var map = new google.maps.Map(document.getElementById('multiple-map'));

  infowindow = new google.maps.InfoWindow({
    //minWidth: 300,
    //maxWidth: 500
  });

  for (i = 0; i < locations.length; i++) {
    marker = new google.maps.Marker({
      map: map,
      position: new google.maps.LatLng(locations[i][0], locations[i][1]),
      title: locations[i][0]
    });

    LatLngList[i] = new google.maps.LatLng(locations[i][0], locations[i][1]);

    google.maps.event.addListener(marker, 'click', (function(marker, i) {
      return function() {

        infowindowContent =
        "<div class='marker-content'>"+
          "<div class='info'>"+
            "<h3>"+locations[i][2]+"</h3>"+
            "<p class='address'>"+
            "<span class='street'>"+locations[i][4]+"</span><br>"+
            "<span class='city'>"+locations[i][2]+"</span>"+
            "<span class='province'>"+locations[i][5]+"</span><br>"+
            "<span class='postalcode'>"+locations[i][6]+"</span></p>"+
            "<p class='phone'>"+locations[i][7]+"</p>"+
            "<p class='directions'><a href="+locations[i][8]+" target='_blank'>"+directionsLabel+"</a></p>"+
          "</div>"+
        "</div>";

        infowindow.setContent(infowindowContent);
        infowindow.open(map, marker);
        // map.setCenter(marker.getPosition());
        // zoom = 14;
        // if (map.getZoom() < zoom) {
        //   map.setZoom(zoom);
        // }
      };
    })(marker, i));
  }

  var bounds = new google.maps.LatLngBounds();
  for (i = 0; i < LatLngList.length; i++) {
    bounds.extend(LatLngList[i]);
  }
  map.setCenter(bounds.getCenter());
  map.fitBounds(bounds);
};


mapSingle = function() {

  var latitude = parseFloat($('footer .coord .latitude').text());
  var longitude = parseFloat($('footer .coord .longitude').text());
  var LatLng = {lat: latitude, lng: longitude};

  var map = new google.maps.Map(document.getElementById('single-map'), {
    zoom: 13,
    center: LatLng
  });

  var marker = new google.maps.Marker({
    position: LatLng,
    map: map,
  });
};

})(jQuery); // Fully reference jQuery after this point.
